import config from '@/config'
import { getStore, setStore } from '@/utils/store'

/**
 * px根据设计稿转vw
 * @param px
 * @returns {number}
 */
export function pxToVw(px) {
  let precision = 5
  return Math.round(((px * 100) / config.designWidth) * Math.pow(10, precision)) / Math.pow(10, precision)
}

/**
 * 判断是否为空
 * @param val
 * @returns {boolean}
 */
export function validateNull(val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

/**
 * 获取第一次进入的路由
 * @returns {*|number}
 */
export function getFirstEnterPath() {
  return getStore({ name: 'firstUrl' })
}

/**
 * 设置第一次进入的路由
 * @returns {*|number}
 */
export function setFirstEnterPath(path) {
  setStore({ name: 'firstUrl', content: path, type: true })
}

export function getStoreToken() {
  return getStore({ name: 'token' })
}

export function setStoreToken(token) {
  setStore({ name: 'token', content: token })
}
