import Vue from 'vue'
import MLoadingV from '@/components/MLoading/main.vue'
import { validateNull } from '@/utils'

const MLoadingConstructor = Vue.extend(MLoadingV)

let instance = null

const initInstance = () => {
  instance = new MLoadingConstructor({
    el: document.createElement('div')
  })
}
const MLoading = options => {
  if (Vue.prototype.$isServer) return
  //初始化
  if (!instance) {
    initInstance()
  }
  // 设置prop
  if (typeof options === 'string' || validateNull(options)) {
    options = {
      text: options
    }
  }
  for (let prop in options) {
    if (options.hasOwnProperty(prop)) {
      instance[prop] = options[prop]
    }
  }
  document.body.appendChild(instance.$el)
  document.body.classList.add('overflow-hidden')
  return instance
}

MLoading.close = () => {
  document.body.classList.remove('overflow-hidden')

  if (!instance) return
  instance.close()
}

MLoading.show = options => {
  MLoading(options)
  instance.show()
}

export { MLoading }
export default {
  install(Vue) {
    Vue.prototype.$mLoading = MLoading
  }
}
