<template>
  <div class="m-loading" v-if="loading">
    <div class="m-loading-box">
      <div class="m-loading-box-icon">
        <svg-icon icon-class="loading"></svg-icon>
      </div>
      <div class="m-loading-box-text" v-if="text">{{ text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String | Number,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    close() {
      this.loading = false
    },
    show() {
      this.loading = true
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.m-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.7);

    padding: 40px;

    border-radius: 25px;

    &-icon {
      ::v-deep .svg-icon {
        height: 75px;
        width: 75px;
        animation: rotate 1.5s infinite linear;
      }
    }

    &-text {
      padding-top: 15px;
      font-size: 28px;
    }
  }
}
</style>
